import './DeliveryAttempts.scss';

import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { WithPagination } from '../../../types/api';
import { Unpacked } from '../../../types/util';
import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export type DeliveryAttemptsItem = {
  id: number;
  courierName: string;
  customerId: number;
  customerName: string;
  courierId: number;
  comment: string;
  operation: number;
  deliveryId: number;
  adresnica_id: number;
  device_id: number;
  priem_dostava: string;
  reasonId: string;
  reasonName: string;
  nareden_obid: string;
  status: string;
  latitude: string;
  longitude: string;
  date: string;
};

export const setOperation = (operation: number, t: TFunction) => {
  return operation === 1 ? t('Receive') : t('Delivery');
};

export type DeliveryAttemptsItemList = WithPagination<DeliveryAttemptsItem[]>; // GET /delivery-attempts

export const tableStorageKey = 'deliveryAttempts_dataTable';

export type Row = Unpacked<DeliveryAttemptsItemList>;

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    date: t('Date'),
    operation: t('Operation'),
    courierId: t('Courier ID'),
    courierName: t('Courier Name'),
    customerId: t('Customer ID'),
    customerName: t('Customer Name'),
    reasonId: t('Reason ID'),
    reasonName: t('Reason Name'),
    comment: t('Comment'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  readDeliveryAttemptGuard: boolean,
  deleteDeliveryAttemptGuard: boolean
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'date':
      return {
        body: (row: DeliveryAttemptsItem) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'operation':
      return {
        body: (row: DeliveryAttemptsItem) => setOperation(row[column], t),
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'courierId':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'courierName':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'customerId':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'customerName':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'reasonId':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'reasonName':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'actions':
      return {
        body: (row: DeliveryAttemptsItem) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View'),
                  icon: 'fas fa-boxes',
                  disabled: !readDeliveryAttemptGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view');
                    setCaller('context-menu');
                  },
                },
                // {
                //   label: t('Export to CSV'),
                //   icon: 'fas fa-file-csv',
                //   disabled: !exportToCsvGuard,
                //   command: () => {
                //     setContextMenuSelection(row);
                //     setAction('export-to-csv');
                //     setCaller('context-menu');
                //   },
                // },
                {
                  label: t('Delete'),
                  icon: 'fas fa-trash',
                  disabled: !deleteDeliveryAttemptGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('delete');
                    setCaller('context-menu');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
