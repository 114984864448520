import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useCallback, useMemo } from 'react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import usePrevious from '../../../hooks/usePrevious';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import * as oAuthGuards from '../../../utils/constants/auth/oAuthClients';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import { copyToClipboard } from '../../../utils/helpers/misc';
import { errorToast, infoToast } from '../../../utils/helpers/primereact';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddEditDialog from './Dialogs/AddEdit/AddEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import EmailCredentialsDialog from './Dialogs/EmailCredentials/EmailCredentialsDialog';
import ViewDialog from './Dialogs/View/ViewDialog';
import {
  ApiData,
  DialogRouteParams,
  OAuthClient,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './OAuthClients.functions';
import useTableFilters from './useTableFilters';

function OAuthClients(): JSX.Element {
  const { t } = useTranslation();
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const { id } = useParams<DialogRouteParams>();
  const { toastRef, bottomRightToastRef } = useContext(ToastContext);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<OAuthClient>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'oAuthClients',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(c as keyof typeof columnHeadersMap)
    );
  console.log('queryString(httpFiltersObj) ', queryString(httpFiltersObj));
  const { data, error, reload, isLoading } = useAxiosHook<ApiData>(
    '/oauthclients' + queryString(httpFiltersObj)
  );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(RoutePaths.OAuthClients, RoutePaths.AddOAuthClient);

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.OAuthClients,
    constructIdRoute(RoutePaths.ViewOAuthClient, id ?? selection?.id)
  );

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.OAuthClients,
    constructIdRoute(RoutePaths.EditOAuthClient, id ?? selection?.id)
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.OAuthClients,
    constructIdRoute(RoutePaths.DeleteOAuthClient, id ?? selection?.id)
  );

  const {
    show: showEmailCredentialsDialog,
    hide: hideEmailCredentialsDialog,
    isVisible: isEmailCredentialsDialogVisible,
  } = useRouteDialog(
    RoutePaths.OAuthClients,
    constructIdRoute(RoutePaths.EmailOAuthClient, id ?? selection?.id)
  );

  const {
    data: clientData,
    error: clientError,
    isLoading: isClientLoading,
  } = useAxiosHook<OAuthClient>(`/oauthclients/${id}`, {
    skipWhen: !id || !oAuthGuards.readCredential,
  });

  const prevClientError = usePrevious(clientError);

  useEffect(() => {
    if (!clientError || clientError === prevClientError) {
      return;
    }

    errorToast(
      toastRef,
      'Error',
      `An error occured while reading client data.`
    );

    hideAddDialog();
  }, [clientError, hideAddDialog, prevClientError, toastRef]);

  usePageTitleToggler(
    t('Add credentials - OAuth Clients'),
    t('OAuth Clients'),
    isAddDialogVisible
  );

  usePageTitleToggler(
    isClientLoading
      ? t('Loading...')
      : t('{{clientName}} - OAuth Clients', {
          clientName: clientData?.client_name,
        }),
    t('OAuth Clients'),
    isViewDialogVisible
  );

  usePageTitleToggler(
    isClientLoading
      ? t('Loading...')
      : t('Edit {{clientName}} - OAuth Clients', {
          clientName: clientData?.client_name,
        }),
    t('OAuth Clients'),
    isEditDialogVisible
  );

  usePageTitleToggler(
    isClientLoading
      ? t('Loading...')
      : t('Delete {{clientName}} - OAuth Clients', {
          clientName: clientData?.client_name,
        }),
    t('OAuth Clients'),
    isDeleteDialogVisible
  );

  const handleCMViewClick = useCallback(() => {
    showViewDialog();
  }, [showViewDialog]);

  const readGuard = useEndpointGuard(oAuthGuards.readCredential);
  const createGuard = useEndpointGuard(oAuthGuards.newCredential);
  const editGuard = useEndpointGuard(oAuthGuards.editCredential);
  const deleteGuard = useEndpointGuard(oAuthGuards.deleteCredential);
  const emailGuard = useEndpointGuard(oAuthGuards.emailCredential);

  useEffect(() => {
    if (!createGuard) {
      hideAddDialog();
    }
  }, [createGuard, hideAddDialog]);

  useEffect(() => {
    if (!editGuard) {
      hideEditDialog();
    }
  }, [editGuard, hideEditDialog]);

  useEffect(() => {
    if (!deleteGuard) {
      hideDeleteDialog();
    }
  }, [deleteGuard, hideDeleteDialog]);

  useEffect(() => {
    if (!emailGuard) {
      hideEmailCredentialsDialog();
    }
  }, [emailGuard, hideEmailCredentialsDialog]);

  const contextMenuModel = useMemo(() => {
    function handleCMEditClick() {
      showEditDialog();
    }

    function handleCMDeleteClick() {
      showDeleteDialog();
    }

    function handleCMEmailCredentialsClick() {
      showEmailCredentialsDialog();
    }

    function handleCMCopyDocUrlClick() {
      const text =
        process.env.REACT_APP_API_URL +
        '/api/doc.json?client_id=' +
        selection?.client_id;

      function handleCopyingSuccess() {
        if (!bottomRightToastRef?.current) {
          return;
        }

        infoToast(
          bottomRightToastRef,
          t('Clipboard'),
          t('The documentation URL has been copied to your clipboard.')
        );
      }

      function handleCopyingError() {
        if (!bottomRightToastRef?.current) {
          return;
        }

        errorToast(
          bottomRightToastRef,
          t('Error'),
          t(
            'Failed copying to your clipboard. Please make sure your browser is up to date.'
          )
        );
      }

      copyToClipboard(text, handleCopyingSuccess, handleCopyingError);
    }

    return generateContextMenu(
      t,
      selection,
      handleCMViewClick,
      editGuard,
      handleCMEditClick,
      deleteGuard,
      handleCMDeleteClick,
      emailGuard,
      handleCMEmailCredentialsClick,
      handleCMCopyDocUrlClick
    );
  }, [
    bottomRightToastRef,
    deleteGuard,
    editGuard,
    emailGuard,
    handleCMViewClick,
    selection,
    showDeleteDialog,
    showEditDialog,
    showEmailCredentialsDialog,
    t,
  ]);

  function handleAddCredentialsBtnClick() {
    showAddDialog();
  }

  return (
    <div className="page oauth-clients-page">
      <HeaderPages
        title={t('OAuth Clients')}
        subtitle={t('Manage third-party access to your application')}
        icon={faKey}
      >
        {createGuard && (
          <Button
            type="button"
            label={t('New credentials')}
            icon="fas fa-plus"
            disabled={isLoading}
            onClick={handleAddCredentialsBtnClick}
            className="main-btn"
            data-cy="add-credentials"
          />
        )}
      </HeaderPages>

      <AddEditDialog
        data={clientData}
        isLoading={isClientLoading}
        isEditDialog={isEditDialogVisible}
        visible={isAddDialogVisible || isEditDialogVisible}
        tableDataReload={reload}
        onHide={() => {
          hideAddDialog();
          hideEditDialog();
        }}
      />

      <ViewDialog
        visible={isViewDialogVisible}
        data={clientData}
        isLoading={isClientLoading}
        onHide={hideViewDialog}
      />

      <DeleteDialog
        data={clientData}
        isLoading={isClientLoading}
        visible={isDeleteDialogVisible}
        tableDataReload={reload}
        onHide={hideDeleteDialog}
      />

      <EmailCredentialsDialog
        data={clientData}
        isLoading={isClientLoading}
        visible={isEmailCredentialsDialogVisible}
        onHide={hideEmailCredentialsDialog}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={170}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            contextMenuModel={contextMenuModel}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={readGuard ? handleCMViewClick : () => {}}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default OAuthClients;
