import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { Toast } from 'primereact/toast';
import { RefObject } from 'react';

import { FileTypes } from '../../enums/files';
// import { ConfigCollection } from '../../types/api/configs';
// import { AddressBookPdfQueryParams } from '../../types/reports/addressBook';
// import {
//   BatchOrderPdfStickerQueryParams,
//   OrderPdfStickerQueryParams,
// } from '../../types/reports/sticker';
// import { WithTemplate, XOR } from '../../types/util';
import api from '../../utils/api';
import { specialCharsRegex } from '../constants/regex';
import { findSingleConfig } from './api/configs';
import { queryString } from './http';
import { errorToast, infoToast } from './primereact';

function saveFile(data: BlobPart | null, name: string, type: string): void {
  if (data === null) {
    return;
  }

  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);

  document.body.appendChild(link);
  link.click();
  link.remove();
}

// Note: these save[Extension]File functions are somewhat obsolete.
//  You may want to use downloadFile instead.
export function saveCsvFile(data: BlobPart, name: string): void {
  saveFile(data, name, FileTypes.CSV);
}

export function saveXlsxFile(data: BlobPart, name: string): void {
  saveFile(data, name, FileTypes.XLSX);
}

export function savePdfFile(data: BlobPart, name: string): void {
  saveFile(data, name, FileTypes.PDF);
}

export function saveJsonFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.JSON);
}

export function saveXmlFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.XML);
}

export function saveTxtFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.TXT);
}

export function printPDF(
  axiosConfig: string,
  toastRef: RefObject<Toast> | undefined
): any {
  if (toastRef?.current) {
    infoToast(
      toastRef,
      i18next.t('Loading...'),
      i18next.t('Your file is being processed and will be ready for print.'),
      { life: 2500 }
    );
  }
  return api({ responseType: 'arraybuffer', url: axiosConfig }).then((res) => {
    const blob = new Blob([res.data], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  });
}

// Prefer using this function for file downloads
export function downloadFile<T, U>(
  axiosConfig: string | AxiosRequestConfig,
  name: string,
  type: FileTypes,
  toastRef: RefObject<Toast> | undefined,
  responseModifier?: (response: T) => U
): void {
  if (toastRef?.current) {
    infoToast(
      toastRef,
      i18next.t('Downloading...'),
      i18next.t(
        'Your file is being processed and will start downloading soon.'
      ),
      { life: 2500 }
    );
  }

  api({
    responseType: responseModifier === undefined ? 'arraybuffer' : undefined,
    ...(typeof axiosConfig === 'string'
      ? {
          url: axiosConfig,
        }
      : axiosConfig),
  })
    .then((res) => {
      if (typeof responseModifier === 'function') {
        return responseModifier(res.data);
      }

      return res.data;
    })
    .then((res) => {
      const extension = getFileExtension(type);

      saveFile(res, name + '.' + extension, type);
    })
    .catch(() => {
      errorToast(
        toastRef,
        i18next.t('Error'),
        i18next.t(
          'There was an error processing your request. Your file could not be downloaded. Please try again later.'
        )
      );
    });
}

export async function getParamTypeDownload(
  orderer_id: any,
  typePrint: 'AddressBook' | 'Sticker'
) {
  let typeParam;

  if (orderer_id) {
    const ordererData = await api(`/clients/${orderer_id}/reports`);
    typeParam = ordererData?.data[typePrint!];
  } else {
    const config = await api.get('/configs');
    const template = findSingleConfig(
      config?.data,
      'Report',
      typePrint === 'AddressBook'
        ? 'AddressBookDefaultTemplate'
        : 'StickerDefaultTemplate'
    )?.value;
    typeParam = template;
  }

  return typeParam;
}

export async function printAddressOrSticker(
  queryParams: any,
  typePrint: 'AddressBook' | 'Sticker',
  orderer: any,
  toastRef: RefObject<Toast> | undefined
) {
  const typePrintURL = typePrint === 'AddressBook' ? 'address-book' : 'sticker';
  const template = await getParamTypeDownload(orderer, typePrint); // AddressBook param

  const queryStr = queryString({
    ...queryParams,
    template, // send name param template because of URL pathname
  });

  printPDF(
    `${process.env.REACT_APP_REPORT_URL}/${typePrintURL}/pdf${queryStr}`,
    toastRef
  ).then((data: any) => {
    window.open(data, '_blank')?.print();
    setTimeout(() => {
      window.close();
    }, 1000);
  });
}

export function getFileExtension(type: FileTypes): string {
  switch (type) {
    case FileTypes.CSV:
      return 'csv';

    case FileTypes.JSON:
      return 'json';

    case FileTypes.PDF:
      return 'pdf';

    case FileTypes.XLSX:
      return 'xlsx';

    case FileTypes.XML:
      return 'xml';

    case FileTypes.TXT:
      return 'txt';

    case FileTypes.PNG:
      return 'png';

    case FileTypes.JPEG:
      return 'jpg';
  }
}

/**
 * Note: File extension is not included.
 * For file extensions, please consider using `getFileExtension`.
 */
export function getFileName(
  page: string,
  identifier: string | string[] | undefined,
  timestamp: boolean = false
): string {
  const processedIdentifiers =
    identifier &&
    (typeof identifier === 'string' ? [identifier] : identifier)
      .map((i) => i.replace(specialCharsRegex, '-'))
      .map((i) => i.replace(/-{2,}/g, '-'))
      .join('_')
      .replace(/_{2,}/g, '_');

  return (
    i18next.t('Accura') +
    '_' +
    page +
    (processedIdentifiers?.length ? '_' + processedIdentifiers : '') +
    (timestamp ? '_' + dayjs().format('YYYYMMDDHHmmss') : '')
  );
}

// -------------------------------- //
// ------- !!! NOT USED !!! ------- //
// -------------------------------- //
//  If the CSV contains only one column, we'll try to return the best suitable delimiter.
//  If all fails, chances are the CSV file is seriously bad, so we return the fallback delimiter.
export function detectCsvDelimiter(data: string): string {
  const potentialDelimiters = [',', '\t', ';', '|'];
  const fallbackDelimiter = ',';
  let delimitersForWhichColumnLengthIsOne: string[] = [];

  const rows = data.trim().split('\n');

  const delimiter = potentialDelimiters.find((d) => {
    const expectedColumnsLength = rows[0].split(d).length;

    if (expectedColumnsLength === 1) {
      delimitersForWhichColumnLengthIsOne.push(d);

      return false;
    }

    for (const row of rows) {
      if (row.split(d).length !== expectedColumnsLength) {
        return false;
      }
    }

    return true;
  });

  if (delimiter) {
    return delimiter;
  }

  if (delimitersForWhichColumnLengthIsOne.length === 0) {
    return fallbackDelimiter;
  }

  return (
    potentialDelimiters.find((d) => {
      for (const row of rows) {
        if (row.split(d).length !== 1) {
          return false;
        }
      }

      return true;
    }) ?? fallbackDelimiter
  );
}

function csvDelimiterToRegex(delimiter: string): string {
  switch (delimiter) {
    case '\t':
      return '\\t';

    case '|':
      return '\\|';

    default:
      return delimiter;
  }
}

function removeEmptyRowsFromCsvContent(
  content: string,
  delimiter: string
): string {
  const pattern = new RegExp(
    '^("\\s*"' + csvDelimiterToRegex(delimiter) + ')+("\\s*")*$'
  );

  return content
    .split('\n')
    .filter(function (row) {
      return row.replace(pattern, '') !== '';
    })
    .join('\n');
}

export function saveFileFromHtmlCanvas(
  selector: string,
  name: string,
  type: FileTypes.PNG | FileTypes.JPEG,
  quality: number = 1
) {
  const canvas = document.querySelector<HTMLCanvasElement>(selector);

  if (!canvas) {
    return;
  }

  canvas.toBlob((blob) => saveFile(blob, name, type), type, quality);
}

function removeNewLineFromCsvCells(content: string): string {
  // all fields are quoted and separated with delimiter
  var firstQuoteIndex = 0;
  var nextQuoteIndex = 0;
  var str = content;
  var ok = true;

  while (str.length > 0 && ok) {
    firstQuoteIndex = str.indexOf('"');
    nextQuoteIndex = str.substring(firstQuoteIndex + 1).indexOf('"');
    if (nextQuoteIndex > 0) {
      nextQuoteIndex = nextQuoteIndex + firstQuoteIndex + 1;
    }

    if (firstQuoteIndex >= 0 && nextQuoteIndex > 0) {
      const fieldValue = str.substring(firstQuoteIndex, nextQuoteIndex + 1);
      if (fieldValue.indexOf('\n') >= 0) {
        content = content.replace(
          fieldValue,
          fieldValue.replace(/[\r\n]+/g, ' ')
        );
      }

      str = str.substring(nextQuoteIndex + 1);
    } else {
      ok = false;
    }
  }

  return content;
}

function trimCsvCells(content: string, delimiter: string): string {
  return content
    .split('\n')
    .map((row) =>
      row
        .split(delimiter)
        .map(
          (cell) =>
            '"' +
            cell
              .trim()
              // Unwrap cell from " or ' characters
              .replace(/^"(.+)"$/, '$1')
              .replace(/^'(.+)'$/, '$1')
              // Just in case trim
              .trim() +
            '"'
        )
        .join(delimiter)
    )
    .join('\n');
}

export function cleanUpCsv(content: string, delimiter: string): string {
  const withNoNewLines = removeNewLineFromCsvCells(content);
  const withNoEmptyRows = removeEmptyRowsFromCsvContent(
    withNoNewLines,
    delimiter
  );
  return trimCsvCells(withNoEmptyRows, delimiter);
}
