import './CreateEditDialog.scss';

import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  getInitialValues,
  getValidationSchema,
} from './CreateEditDialog.functions';
import DialogContainer from './DialogContainer';

function CreateEditDialog({
  formRef,
  isShown,
  isEditDialog,
  selectedRow,
  onHide,
  onFormSubmision,
  isFormSubmissionRequestLoading,
  headquarterData,
  isHeadquarterDataLoading,
  reloadHeadquarterData,
}) {
  const { t } = useTranslation();

  const { data: configsData, isLoading: isConfigsDataLoading } = useAxiosHook(
    '/configs',
    { skipWhen: !isShown }
  );

  const { data: clientData, isLoading: isClientDataLoading } = useAxiosHook(
    `/clients/${selectedRow?.id}`,
    {
      skipWhen: !isShown || !isEditDialog || !selectedRow?.id,
    }
  );

  const validationSchema = useMemo(
    () => getValidationSchema(t, configsData, clientData, isEditDialog),
    [configsData, isEditDialog, clientData, t]
  );

  const {
    data: headquarterSubsidiaries,
    reload: headquarterSubsidiariesReload,
  } = useAxiosHook({ skipWhen: !isShown || !selectedRow?.id });

  const { data: chargingDepartmentsData } = useAxiosHook(
    `/clients/${selectedRow?.id}/departments`,
    { skipWhen: !isShown || !selectedRow?.id }
  );

  const prevClientData = usePrevious(clientData);

  useEffect(() => {
    if (
      !clientData ||
      clientData === prevClientData ||
      !clientData.direkcija_id
    ) {
      return;
    }

    reloadHeadquarterData({
      url: `/clients/${clientData.direkcija_id}`,
    });

    headquarterSubsidiariesReload({
      url: `/clients/${clientData.direkcija_id}/subsidiaries`,
    });
  }, [
    clientData,
    headquarterSubsidiariesReload,
    prevClientData,
    reloadHeadquarterData,
  ]);

  const isLoading = isClientDataLoading || isConfigsDataLoading;

  const dialogHeader = isEditDialog
    ? selectedRow?.ime ?? ''
    : t('Add a client');

  const dialogFooter = isLoading ? null : (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />
      <Button
        type="button"
        label={
          isFormSubmissionRequestLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Add client')
        }
        disabled={isFormSubmissionRequestLoading || isHeadquarterDataLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        data-cy="submit"
      />
    </>
  );

  const initialValues = useMemo(
    () =>
      getInitialValues(
        t,
        isEditDialog,
        clientData,
        configsData,
        chargingDepartmentsData
      ),
    [chargingDepartmentsData, clientData, configsData, isEditDialog, t]
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      blockScroll
      resizable={false}
      maximizable
      maximized={isOnMobile}
      className={`clients-create-edit-dialog ${isLoading ? 'loading' : ''}`}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onFormSubmision}
      >
        <Form>
          <DialogContainer
            isShown={isShown}
            isEditDialog={isEditDialog}
            clientData={isEditDialog ? clientData : {}}
            isClientDataLoading={isLoading}
            headquarterData={headquarterData}
            isHeadquarterDataLoading={isHeadquarterDataLoading}
            reloadHeadquarterData={reloadHeadquarterData}
            headquarterSubsidiaries={headquarterSubsidiaries}
            chargingDepartmentsData={chargingDepartmentsData}
            configsData={configsData}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default CreateEditDialog;
