import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { ImportListTypeCollection } from '../../../types/api/importlisttypes';
import { noColumnStyle } from '../../../utils/constants/tables';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'listTypes_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    id: t('ID'),
    name: t('Name'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  isViewShown: boolean,
  isEditShown: boolean,
  isExportToExcelOrCSVShown: boolean
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'id':
      return {
        style: {
          width: 80,
        },
        bodyStyle: {
          width: 80,
        },
      };

    case 'name':
      return {
        style: {
          width: 'auto',
          minWidth: 350,
        },
        bodyStyle: {
          width: 'auto',
          minWidth: 350,
        },
      };

    case 'actions':
      return {
        body: (row: ImportListTypeCollection) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View'),
                  icon: 'fas fa-search',
                  disabled: !isViewShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !isEditShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                  },
                },
                {
                  label: t('Export to Excel'),
                  icon: 'fas fa-file-excel',
                  disabled: !isExportToExcelOrCSVShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-excel');
                  },
                },
                {
                  label: t('Export to CSV'),
                  icon: 'fas fa-file-csv',
                  disabled: !isExportToExcelOrCSVShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-csv');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}

export function sortFields(fields: any) {
  if (!fields) {
    return [];
  }

  return [...fields].sort((a, b) => a.index - b.index);
}
