import {
  faBook,
  faBorderAll,
  faBox,
  faCarSide,
  faCashRegister,
  faClipboardCheck,
  faClipboardList,
  faCoins,
  faCreditCard,
  faCube,
  faCubes,
  faDollarSign,
  faDoorOpen,
  faEye,
  faFileAlt,
  faFileContract,
  faFileInvoice,
  faFileSignature,
  faHandHoldingUsd,
  faKey,
  faList,
  faLocationArrow,
  faMap,
  faMapMarkedAlt,
  faMobileAlt,
  faMoneyBillAlt,
  faNetworkWired,
  faPallet,
  faPercentage,
  faPortrait,
  faShippingFast,
  faTags,
  faTerminal,
  faTools,
  faTrashAlt,
  faTruckLoading,
  faUser,
  faUserTie,
  faUsers,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';

import { ReduxState } from '../../../types/redux';
import * as clientsGuards from '../../../utils/constants/auth/clients';
import * as codCreateWarrantsGuards from '../../../utils/constants/auth/cod/createWarrants';
import * as codWarrantsGuards from '../../../utils/constants/auth/cod/warrants';
import * as courierMapGuards from '../../../utils/constants/auth/courierMap';
import * as courierPickupAssignmentGuards from '../../../utils/constants/auth/courierPickupAssignment';
import * as courierShipmentDeliveryAssignment from '../../../utils/constants/auth/courierShipmentAssignment';
import * as courierShipmentReceptionGuards from '../../../utils/constants/auth/courierShipmentReception';
import * as customerOrdersGuards from '../../../utils/constants/auth/customerOrders';
import * as customerShipmentReceptionGuards from '../../../utils/constants/auth/customerShipmentReception';
import * as dashboardGuards from '../../../utils/constants/auth/dashboard';
import * as deliveryAttemptsGuards from '../../../utils/constants/auth/deliveryAttempts';
import * as deviceManagementGuards from '../../../utils/constants/auth/deviceManagement';
import * as employeesGuards from '../../../utils/constants/auth/employees';
import * as handOverToCustomerGuards from '../../../utils/constants/auth/handOverToCustomer';
import * as hubShipmentDistributionGuards from '../../../utils/constants/auth/hubShipmentDistribution';
import * as hubShipmentReceptionGuards from '../../../utils/constants/auth/hubShipmentReception';
import * as hubsReportsGuards from '../../../utils/constants/auth/hubsReports';
import * as importedListsGuards from '../../../utils/constants/auth/importedLists';
import * as invoicesGuards from '../../../utils/constants/auth/invoices';
import * as listTypesGuards from '../../../utils/constants/auth/listTypes';
import * as logsGuards from '../../../utils/constants/auth/logs';
import * as oAuthClients from '../../../utils/constants/auth/oAuthClients';
import * as ordersGuards from '../../../utils/constants/auth/orders';
import * as priceListsGuards from '../../../utils/constants/auth/priceLists';
import * as pricesGuards from '../../../utils/constants/auth/prices';
import * as receptionAndDeliveryMap from '../../../utils/constants/auth/receptionAndDeliveryMap';
import * as regionsGuards from '../../../utils/constants/auth/regions';
import * as settingsGuards from '../../../utils/constants/auth/settings';
import * as tariffGuards from '../../../utils/constants/auth/tariff';
import * as trackShipmentByLocationGuards from '../../../utils/constants/auth/trackShipmentsByLocation';
import * as transferCourierToCourierGuards from '../../../utils/constants/auth/transferCourierToCourier';
import * as vehiclesGuards from '../../../utils/constants/auth/vehicles';
import * as warehousesGuards from '../../../utils/constants/auth/warehouses';
import { doesEndpointGuardMatch } from '../../../utils/helpers/auth';
import * as courierReconciliationGuards from '../../AdminPages/CourierReconciliation/CourierReconciliation.guards';
import CollapsedMenuItem from './CollapsedMenuItem/CollapsedMenuItem';
import Item from './Item/Item';
import { MenuItem } from './SideMenu.types';

export function getProductionMenuItems(t: TFunction): MenuItem[] {
  return [
    {
      type: 'item',
      label: t('Dashboard'),
      icon: faBorderAll,
      path: '/dashboard',
      guard: dashboardGuards.routeGuard,
    },
    {
      type: 'parent',
      label: t('Orders'),
      icon: faBox,
      basePath: '/orders',
      items: [
        {
          type: 'item',
          label: t('Active.THEM'),
          icon: faCube,
          path: '/active',
          guard: ordersGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Deleted.THEM'),
          icon: faTrashAlt,
          path: '/deleted',
          guard: ordersGuards.deletedRouteGuard,
        },
        {
          type: 'item',
          label: t('Batch.THEM'),
          icon: faClipboardList,
          path: '/batch',
          guard: importedListsGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Batch Order Templates'),
          icon: faList,
          path: '/batch-order-templates',
          guard: listTypesGuards.routeGuard,
        },
      ],
    },
    {
      type: 'parent',
      label: t('Logistics'),
      icon: faShippingFast,
      basePath: '/logistics',
      items: [
        {
          type: 'item',
          label: t('Reception From Courier'),
          icon: faPortrait,
          path: '/reception/courier',
          guard: courierShipmentReceptionGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Assignment To Courier'),
          icon: faPortrait,
          path: '/assignment/courier',
          guard: courierShipmentDeliveryAssignment.routeGuard,
        },
        {
          type: 'item',
          label: t('Manual Pickup Assignment To Courier'),
          icon: faCubes,
          path: '/assignment/courier-pickup-assignment',
          guard: courierPickupAssignmentGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Reception From Warehouse'),
          icon: faWarehouse,
          path: '/reception/hub',
          guard: hubShipmentReceptionGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Assignment To Warehouse'),
          icon: faWarehouse,
          path: '/assignment/hub',
          guard: hubShipmentDistributionGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Transfer To Courier'),
          icon: faTruckLoading,
          path: '/reception/transfer-to-courier',
          guard: transferCourierToCourierGuards.routeGuard,
        },
      ],
    },
    {
      type: 'parent',
      label: t('Logistics Monitoring'),
      icon: faEye,
      basePath: '/monitoring',
      items: [
        {
          type: 'item',
          label: t('Reception and Delivery Map'),
          icon: faMapMarkedAlt,
          path: '/reception-and-delivery-map',
          guard: receptionAndDeliveryMap.routeGuard,
        },
        {
          type: 'item',
          label: t('Courier Map'),
          icon: faLocationArrow,
          path: '/courier-map',
          guard: courierMapGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Track Shipments By Location'),
          icon: faPallet,
          path: '/track-shipments-by-location',
          guard: trackShipmentByLocationGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Hubs Reports'),
          icon: faWarehouse,
          path: '/hubs-reports',
          guard: hubsReportsGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Delivery attempts'),
          icon: faShippingFast,
          path: '/deliverytrackings',
          guard: deliveryAttemptsGuards.routeGuard,
        },
      ],
    },
    {
      type: 'item',
      label: t('Customers'),
      icon: faUsers,
      path: '/clients',
      guard: clientsGuards.routeGuard,
    },
    {
      type: 'parent',
      label: t('Finances'),
      icon: faCoins,
      basePath: '/finances',
      items: [
        {
          type: 'item',
          label: t('Courier Reconciliation'),
          icon: faCashRegister,
          path: '/reconciliations',
          guard: courierReconciliationGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Invoices Preview'),
          icon: faFileInvoice,
          guard: invoicesGuards.invoicesRouteGuard,
          path: '/invoices/preview',
        },
        {
          type: 'item',
          label: t('Invoices Creation'),
          icon: faFileInvoice,
          guard: invoicesGuards.invoiceCandidatesRouteGuard,
          path: '/invoices/create',
        },
        {
          type: 'parent',
          label: t('COD'),
          icon: faHandHoldingUsd,
          basePath: '/cod',
          items: [
            {
              type: 'item',
              label: t('Create Warrants'),
              icon: faFileSignature,
              path: '/create-warrants',
              guard: codCreateWarrantsGuards.routeGuard,
            },
            {
              type: 'item',
              label: t('Warrants'),
              icon: faFileContract,
              path: '/warrants',
              guard: codWarrantsGuards.routeGuard,
            },
          ],
        },
      ],
    },
    {
      type: 'parent',
      label: t('Reports'),
      icon: faFileAlt,
      basePath: '/reports',
      items: [
        {
          type: 'item',
          label: t('Customer Orders'),
          icon: faClipboardCheck,
          path: '/customer-orders',
          guard: customerOrdersGuards.routeGuard,
        },
      ],
    },
    {
      type: 'parent',
      label: t('Organizational management'),
      icon: faNetworkWired,
      basePath: '/management',
      items: [
        {
          type: 'item',
          label: t('Warehouses List'),
          icon: faNetworkWired,
          path: '/warehouses-list',
          guard: warehousesGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Employees'),
          icon: faUserTie,
          path: '/employees',
          guard: employeesGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Devices'),
          icon: faMobileAlt,
          path: '/device-management',
          guard: deviceManagementGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Smart POS Terminals'),
          icon: faCreditCard,
          path: '/smart-pos-terminals',
          guard: deviceManagementGuards.routeGuard, // smenis
        },
        {
          type: 'item',
          label: t('Vehicles'),
          icon: faCarSide,
          path: '/vehicles',
          guard: vehiclesGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Regions'),
          icon: faMap,
          path: '/regions',
          guard: regionsGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('API Integrations'),
          icon: faKey,
          path: '/oauth-clients',
          guard: oAuthClients.routeGuard,
        },
      ],
    },
    {
      type: 'parent',
      label: t('Billing'),
      icon: faMoneyBillAlt,
      basePath: '/billing',
      items: [
        {
          type: 'item',
          label: t('Price Lists'),
          icon: faBook,
          path: '/price-lists',
          guard: priceListsGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Prices'),
          icon: faTags,
          path: '/prices',
          guard: pricesGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Tariffs'),
          icon: faPercentage,
          path: '/tariffs',
          guard: tariffGuards.routeGuard,
        },
      ],
    },
    {
      type: 'item',
      label: t('Logs'),
      icon: faTerminal,
      path: '/logs',
      guard: logsGuards.routeGuard,
    },
    {
      type: 'item',
      label: t('Global settings'),
      icon: faTools,
      path: '/settings',
      guard: settingsGuards.routeGuard,
    },
  ];
}

export function getDevMenuItems(t: TFunction): MenuItem[] {
  return [
    {
      type: 'parent',
      label: t('Logistics dev'),
      icon: faShippingFast,
      basePath: '/dev/logistics',
      items: [
        {
          type: 'item',
          label: t('Reception From Customer In The Warehouse'),
          icon: faUsers,
          path: '/reception/customer',
          guard: customerShipmentReceptionGuards.routeGuard,
        },
        {
          type: 'item',
          label: t('Handover To Customer In The Warehouse'),
          icon: faUser,
          path: '/assignment/handover-to-customer',
          guard: handOverToCustomerGuards.routeGuard,
        },
      ],
    },
    {
      type: 'item',
      label: t('Routes'),
      icon: faDoorOpen,
      path: '/dev/management/routes',
    },
    {
      type: 'parent',
      label: t('Reports'),
      icon: faFileAlt,
      basePath: '/dev/reports',
      items: [
        {
          type: 'item',
          label: t('Cashdesk'),
          icon: faHandHoldingUsd,
          path: '/cashdesk',
        },
        {
          type: 'item',
          label: t('Customer Earnings'),
          icon: faDollarSign,
          path: '/customer-earnings',
        },
      ],
    },
    {
      type: 'item',
      label: t('Courier Compensation'),
      icon: faFileInvoice,
      path: '/dev/courier-compensation',
    },
  ];
}

export function renderItems({
  items,
  filter,
  permittedRoutes,
  ignoreGuards = false,
  isMenuExpanded = true,
}: {
  items: MenuItem[];
  filter: string;
  permittedRoutes: ReduxState['user']['permitted_routes'];
  ignoreGuards?: boolean;
  isMenuExpanded: boolean;
}): JSX.Element[] {
  const filterRegex = new RegExp(filter, 'i');
  const filteredItemsByFilter = filter.length
    ? filterItemsByFilter(items, filterRegex)
    : items;
  const filteredItemsByGuards = ignoreGuards
    ? filteredItemsByFilter
    : filterItemsByGuard(filteredItemsByFilter, permittedRoutes);

  return filteredItemsByGuards.map((item, idx) => {
    const key = `${item.type === 'item' ? item.path : ''}-${idx}`;

    if (!isMenuExpanded) {
      return <CollapsedMenuItem key={key} nestingLevel={0} {...item} />;
    }

    return <Item key={key} nestingLevel={0} {...item} />;
  });
}

// Q: Why .push ?
// A: Recursively re-building an array of complex objects means a performance bottleneck.
//  In this specific scenario, we're always returning a new array one way or the other,
//  so, for the sake of performance, it's better to modify the reduce method's original array instead.
function filterItemsByGuard(
  items: MenuItem[],
  permittedRoutes: ReduxState['user']['permitted_routes']
): MenuItem[] {
  return items.reduce<MenuItem[]>((arr, item) => {
    if (item.type === 'item') {
      if (!!item.guard && doesEndpointGuardMatch(item.guard, permittedRoutes)) {
        arr.push(item);
      }
    } else {
      const newItems = filterItemsByGuard(item.items, permittedRoutes);

      if (newItems.length > 0) {
        arr.push({ ...item, items: newItems });
      }
    }

    return arr;
  }, []);
}

function filterItemsByFilter(items: MenuItem[], filter: RegExp): MenuItem[] {
  return items.reduce<MenuItem[]>((arr, item) => {
    if (item.type === 'item') {
      if (item.label.match(filter)) {
        arr.push(item);
      }
    } else {
      const newItems = filterItemsByFilter(item.items, filter);

      if (newItems.length > 0) {
        arr.push({ ...item, items: newItems });
      }
    }

    return arr;
  }, []);
}

function queryMenuItem(items: MenuItem[], path: string): MenuItem | undefined {
  const pathArr = path.split('/');
  const firstPath = pathArr[0];
  const restOfPathArr = pathArr.slice(1).join('/');

  const result = items.find((item) =>
    item.type === 'item'
      ? item.path === `/${firstPath}`
      : item.basePath === `/${firstPath}`
  );

  if (!restOfPathArr.length) {
    return result;
  }

  return result?.type === 'parent'
    ? queryMenuItem(result.items, restOfPathArr)
    : undefined;
}

export function queryMenuItems(
  items: MenuItem[],
  path: string
): MenuItem[] | undefined {
  const pathArr = path.split('/').slice(1);

  const result = pathArr
    .reduce<string[]>((acc, _, idx) => {
      acc.push(pathArr.slice(0, idx + 1).join('/'));
      return acc;
    }, [])
    .map((pathToQuery) => queryMenuItem(items, pathToQuery));

  return !!result.every((item) => item === undefined)
    ? undefined
    : (result.filter(Boolean) as MenuItem[]);
}

export function cascadePaths(items: MenuItem[]): string[] {
  return items.reduce<string[]>((acc, item, idx) => {
    const currentPath =
      (idx > 0 ? acc[idx - 1] : '') +
      (item.type === 'item' ? item.path : item.basePath);

    acc.push(currentPath);
    return acc;
  }, []);
}

export function isItemActive(
  locationPathname: string,
  item: MenuItem
): boolean {
  const itemPathRegex = new RegExp(
    item.type === 'item' ? `${item.path}$` : `^${item.basePath}`
  );

  return itemPathRegex.test(locationPathname);
}
