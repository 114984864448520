import './DeliveryAttempts.scss';

import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import * as importedListsGuards from '../../../utils/constants/auth/importedLists';
import { RoutePaths } from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  DeliveryAttemptsItemList,
  Row,
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './DeliveryAttempts.functions';
import { DeliveryAttemptsView } from './Dialogs/View/DeliveryAttemptsView';
import useTableFilters from './useTableFilters';

function DeliveryAttempts(): JSX.Element {
  const { t } = useTranslation();

  const readDeliveryAttemptGuard = useEndpointGuard(
    importedListsGuards.readShipments
  );

  const deleteDeliveryAttemptGuard = useEndpointGuard(
    importedListsGuards.deleteImportedList
  );

  usePageTitle(t('Delivery attempts'));

  const [action, setAction] = useState<string>('');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'delivery_attempts',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          readDeliveryAttemptGuard,
          deleteDeliveryAttemptGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          readDeliveryAttemptGuard,
          deleteDeliveryAttemptGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      readDeliveryAttemptGuard,
      deleteDeliveryAttemptGuard,
    ]
  );

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, isLoading, reload, error } =
    useAxiosHook<DeliveryAttemptsItemList>(
      {
        url: '/deliverytrackings?page=1&limit=15' + queryString(httpFiltersObj),
      },
      { skipWhen: !canLoadData }
    );

  const {
    show: showDeliveryAttemptsDialog,
    hide: hideDeliveryAttemptsDialog,
    isVisible: isDeliveryAttemptsDialogShown,
  } = useRouteDialog(
    RoutePaths.DeliveryAttemptsList,
    RoutePaths.DeliveryAttemptsView
  );

  function handleViewDialogHide() {
    hideDeliveryAttemptsDialog();
  }

  useEffect(() => {
    if (!readDeliveryAttemptGuard) {
      hideDeliveryAttemptsDialog();
    }
  }, [readDeliveryAttemptGuard]);

  useEffect(() => {
    if (!deleteDeliveryAttemptGuard) {
      // setIsDeleteImportedListDialogShown(false);
    }
  }, [deleteDeliveryAttemptGuard]);

  // Context menu functions
  const handleCMViewClick = useCallback(() => {
    showDeliveryAttemptsDialog();
  }, []);

  const handleDeleteListAndAddresses = useCallback(() => {
    // setIsDeleteImportedListDialogShown(true);
  }, []);

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');
      if (action === 'view') {
        handleCMViewClick();
      }
      if (action === 'delete') {
        handleDeleteListAndAddresses();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    handleDeleteListAndAddresses,
    handleCMViewClick,
  ]);

  const paginatorLeft = data && (
    <span>
      {t('Total')}: {data.pagination.total}
    </span>
  );

  usePageTitleToggler(
    !contextMenuSelection ? t('Loading...') : t('View delivery attempts'),
    t('Delivery attempts'),
    isDeliveryAttemptsDialogShown
  );

  return (
    <div className="page delivery-attempts-list-page">
      <HeaderPages
        title={t('Delivery attempts')}
        subtitle={t('Review and report after delivery attempt.')}
        icon={faShippingFast}
      />

      <DeliveryAttemptsView
        selectedRow={contextMenuSelection}
        isShown={isDeliveryAttemptsDialogShown}
        onHide={handleViewDialogHide}
      />

      {/* <DeleteImportedListDialog
        selectedList={contextMenuSelection}
        isShown={isDeleteImportedListDialogShown}
        onHide={handleDeleteImportedListDialogHide}
        reloadImportedLists={reload}
      /> */}

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            clearSelectionObj={httpFiltersObj}
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            isReloadDisabled={!canLoadData}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={220}
            paginatorLeft={paginatorLeft}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToCSVButton
            onExportToCSVButtonClick={() =>
              (tableRef?.current as any)?.exportCSV()
            }
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default DeliveryAttempts;
