import './ViewOrderDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '../../../../../enums/orders';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { SingleOrder } from '../../Orders.functions';
import ViewOrderContent from './ViewOrderContent';

type Props = {
  visible: boolean;
  data: SingleOrder | any;
  isLoading: boolean;
  onEdit?: () => void;
  onHide: () => void;
};

function ViewActiveOrderDialog({
  visible,
  data,
  isLoading,
  onEdit,
  onHide,
}: Props) {
  const { t } = useTranslation();
  console.log(data);
  const dialogFooter = !isLoading && (
    <>
      {onEdit && (
        <Button
          label={t('Edit')}
          className="p-button-text"
          disabled={data?.status_id >= OrderStatus.Cancelled}
          onClick={onEdit}
        />
      )}
      <Button label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('Order {{serialNo}}', { serialNo: data?.seriski_broj ?? '' })
      }
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-order-dialog"
    >
      {isLoading ? <DialogSpinner /> : <ViewOrderContent data={data} />}
    </Dialog>
  );
}

export default ViewActiveOrderDialog;
