import { faList } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import * as listTypesGuards from '../../../utils/constants/auth/listTypes';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { errorToast } from '../../../utils/helpers/primereact';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import CreateEditDialog from './Dialogs/CreateEditDialog';
import ViewDialog from './Dialogs/ViewDialog';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './ListTypes.functions';
import { Row } from './ListTypes.types';
import useTableFilters from './useTableFilters';

function ListTypes() {
  const { t } = useTranslation();
  const readGuard = useEndpointGuard(listTypesGuards.readListType);
  const createGuard = useEndpointGuard(listTypesGuards.createListType);
  const editGuard = useEndpointGuard(listTypesGuards.editListType);
  const exportToExcelOrCsvGuard = useEndpointGuard(
    listTypesGuards.exportListTypeToExcelOrCsv
  );
  usePageTitle(t('Batch Order Templates'));
  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const [isAddEditDialogShown, setIsAddEditDialogShown] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [action, setAction] = useState<string>('');

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const isEditDialog =
    isAddEditDialogShown && Object.keys(dialogData).length > 0;
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'listTypes',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          readGuard,
          editGuard,
          exportToExcelOrCsvGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          readGuard,
          editGuard,
          exportToExcelOrCsvGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      readGuard,
      editGuard,
      exportToExcelOrCsvGuard,
    ]
  );

  useEffect(() => {
    if (!readGuard) {
      setIsViewDialogShown(false);
    }
  }, [readGuard]);

  useEffect(() => {
    if (!createGuard && isAddEditDialogShown && !isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [createGuard, isAddEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!editGuard && isAddEditDialogShown && isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [editGuard, isAddEditDialogShown, isEditDialog]);

  const { data, isLoading, reload, error } = useAxiosHook(
    '/importlisttypes' + httpFiltersObj
  );

  const {
    isLoading: isListTypeDialogDataLoading,
    reload: reloadListTypeDialogData,
  } = useAxios(undefined, undefined, {
    errorCallback: () => {
      toastRef?.current?.show({
        severity: 'error',
        summary: t('Error'),
        detail: t(
          'An error occured while trying to get data for the import list {{selectedRowName}}.',
          { selectedRowName: selection?.name }
        ),
        life: 5000,
      });
    },
  });

  function handleAddListTypeBtnClick() {
    setDialogData({});
    setIsAddEditDialogShown(true);
  }

  function handleCreateEditDialogHide() {
    setIsAddEditDialogShown(false);
    setDialogData({});
  }

  function handleViewDialogHide() {
    setIsViewDialogShown(false);
  }

  function handleViewDialogEditBtnClick() {
    setIsViewDialogShown(false);
    setIsAddEditDialogShown(true);
  }

  function handleListTypeCreateEdit(
    name: string,
    isCreate: boolean,
    isSuccess: boolean
  ) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('ListType {{name}} has been {{status}} sucessfully', {
            name: name,
            status: isCreate ? t('added') : t('edited'),
          })
        : t('An error occured while adding {{name}}.', { name: name }),
      life: 5000,
    });

    setIsAddEditDialogShown(false);
    setDialogData({});
    reload();
  }

  function handleListFieldSubmit(
    name: string,
    isNew: boolean,
    isSuccess: boolean
  ) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Field {{name}} has been {{status}} successfully', {
            name: name,
            status: isNew ? t('added') : t('edited'),
          })
        : t('An error occured while {{status}} {{name}}.', {
            name: name,
            status: isNew ? t('adding') : t('editing'),
          }),
      life: 5000,
    });
  }

  function handleFieldDefinitionSubmit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Field definitions have been set sucessfully for {{name}}.', {
            name,
          })
        : t('An error occured while setting field definitions for {{name}}.', {
            name,
          }),
      life: 5000,
    });
  }

  const handleCMViewClick = useCallback(() => {
    reloadListTypeDialogData({
      url: `/importlisttypes/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data);
        setIsViewDialogShown(true);
      },
    });
  }, [reloadListTypeDialogData, contextMenuSelection?.id]);

  const handleCMEditClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    reloadListTypeDialogData({
      url: `/importlisttypes/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data);
        setIsAddEditDialogShown(true);
      },
      errorCallback: () => {
        setIsAddEditDialogShown(false);

        if (toastRef?.current) {
          errorToast(
            toastRef,
            t('Error'),
            t('An error occured while reading data.')
          );
        }
      },
    });
  }, [contextMenuSelection, toastRef, t, reloadListTypeDialogData]);

  const handleExportToExcel = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/importlisttypes/${contextMenuSelection?.id}/export/excel`,
      getFileName(t('Batch Order Template'), contextMenuSelection.name),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  const handleExportToCsv = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/importlisttypes/${contextMenuSelection?.id}/export/csv`,
      getFileName(t('Batch Order Template'), contextMenuSelection.name),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view') {
        handleCMViewClick();
      }
      if (action === 'edit') {
        handleCMEditClick();
      }
      if (action === 'export-to-excel') {
        handleExportToExcel();
      }
      if (action === 'export-to-csv') {
        handleExportToCsv();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    handleCMViewClick,
    handleCMEditClick,
    handleExportToExcel,
    handleExportToCsv,
  ]);

  return (
    <div className="page list-types-page">
      <HeaderPages
        title={t('Batch Order Templates')}
        subtitle={t('View and manage templates for batch orders')}
        icon={faList}
      >
        {createGuard && (
          <Button
            type="button"
            label={t('Create a template')}
            icon="fas fa-plus"
            className="main-btn"
            disabled={isLoading}
            onClick={handleAddListTypeBtnClick}
            data-cy="add-btn"
          />
        )}
      </HeaderPages>

      <CreateEditDialog
        isShown={isAddEditDialogShown}
        data={dialogData}
        onHide={handleCreateEditDialogHide}
        onCreateEditSubmit={handleListTypeCreateEdit}
        onListFieldSubmit={handleListFieldSubmit}
        onFieldDefinitionSubmit={handleFieldDefinitionSubmit}
      />
      <ViewDialog
        isShown={isViewDialogShown}
        data={dialogData}
        onHide={handleViewDialogHide}
        onEditBtnClick={handleViewDialogEditBtnClick}
      />
      <Dialog
        visible={isListTypeDialogDataLoading}
        onHide={() => undefined}
        closable={false}
        closeOnEscape={false}
        resizable={false}
        header={t('Loading...')}
      >
        <ProgressSpinner
          fill="#ccc"
          strokeWidth="4"
          style={{ width: 360, textAlign: 'center', overflow: 'hidden' }}
        />
      </Dialog>

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            filterHeight={170}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={readGuard ? handleCMViewClick : () => {}}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default ListTypes;
