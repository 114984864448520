import '../../Clients/Dialogs/CreateEditDialog/Tabs/Fields/NotificationsTab/NotificationsTab.scss';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tree } from 'primereact/tree';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import {
  getNotificationChannel,
  getNotificationRecipient,
  getNotificationType,
} from '../../../../utils/constants/entities/notifications';
import Tooltip from '../../../tooltip/Tooltip/Tooltip';
import { ckEditorConfig } from '../../Clients/Dialogs/CreateEditDialog/CreateEditDialog.functions';

function NotificationsCustomersForm() {
  const { t } = useTranslation();
  const notificationType = useMemo(() => getNotificationType(t), [t]);
  const notificationChannel = useMemo(() => getNotificationChannel(t), [t]);
  const notificationRecipient = useMemo(() => getNotificationRecipient(t), [t]);

  const { values, setFieldValue } = useFormikContext();

  const isNotificationEnabled = (nChannel, values, key) => {
    const currentValue = values[key];

    // Email
    if (parseInt(nChannel) === 1) {
      return currentValue?.title && currentValue?.message;
    }

    // SMS
    return currentValue?.message;
  };

  const { data: defaultNotificationsData } = useAxiosHook(
    '/notifications/default'
  );

  const defaultNotifications = useMemo(() => {
    if (!(defaultNotificationsData?.length > 0)) {
      return;
    }

    let returnObj = {};

    for (const n of defaultNotificationsData) {
      returnObj[`${n.event}-${n.actor}-${n.channel}`] = n;
    }

    return returnObj;
  }, [defaultNotificationsData]);

  const notificationTree = useMemo(() => {
    let tree = [
      {
        key: '-1',
        label: (
          <div className="notification-tree-column-container main">
            <div className="notification-tree-column">
              {t('Notification Type')}
            </div>
            <div className="notification-tree-column notification-info-column" />
            <div className="notification-tree-column">{t('Orderer Email')}</div>
            <div className="notification-tree-column">{t('Orderer SMS')}</div>
            <div className="notification-tree-column">{t('Sender Email')}</div>
            <div className="notification-tree-column">{t('Sender SMS')}</div>
            <div className="notification-tree-column">
              {t('Recipient Email')}
            </div>
            <div className="notification-tree-column">{t('Recipient SMS')}</div>
          </div>
        ),
      },
    ];

    for (let nType of notificationType) {
      let rootChildren = [];

      for (let nrType of notificationRecipient) {
        for (let nChannel of notificationChannel) {
          rootChildren.push({
            key: `${nType.id}-${nrType.id}-${nChannel.id}`,
            label: `${nrType.title} ${nChannel.title}`,
            children: [
              {
                key: `${nType.id}-${nrType.id}-${nChannel.id}-0`,
                label:
                  nChannel.id === 1 ? (
                    <div style={{ width: '100%' }}>
                      <label
                        htmlFor={`${nType.id}-${nrType.id}-${nChannel.id}.title`}
                      >
                        {t('Subject')}
                      </label>
                      <div className="p-fluid">
                        <Field
                          as={InputText}
                          name={`${nType.id}-${nrType.id}-${nChannel.id}.title`}
                          value={
                            values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                              ?.title
                            //   ??
                            // defaultNotifications?.[
                            //   `${nType.id}-${nrType.id}-${nChannel.id}`
                            // ]?.title
                          }
                          // disabled={
                          //   !values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                          //     ?.isChecked
                          // }
                          className={`notification-${nType.id}-${nrType.id}-${nChannel.id}-subject`}
                        />
                      </div>

                      <label
                        style={{
                          display: 'block',
                          marginTop: 8,
                        }}
                      >
                        {t('Body')}
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={ckEditorConfig}
                        data={
                          values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                            ?.message
                          //   ??
                          // defaultNotifications?.[
                          //   `${nType.id}-${nrType.id}-${nChannel.id}`
                          // ]?.message
                        }
                        // disabled={
                        //   !values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                        //     ?.isChecked
                        // }
                        onBlur={(_, editor) =>
                          setFieldValue(
                            `${nType.id}-${nrType.id}-${nChannel.id}.message`,
                            editor.getData(),
                            false
                          )
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <label
                        htmlFor={`${nType.id}-${nrType.id}-${nChannel.id}.message`}
                      >
                        {t('Message')}
                      </label>
                      <br />
                      <Field
                        as={InputTextarea}
                        name={`${nType.id}-${nrType.id}-${nChannel.id}.message`}
                        value={
                          values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                            ?.message
                          // !== null
                          // ? values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                          //     ?.message
                          // : defaultNotifications?.[
                          //     `${nType.id}-${nrType.id}-${nChannel.id}`
                          //   ]?.message
                        }
                        // disabled={
                        //   !values[`${nType.id}-${nrType.id}-${nChannel.id}`]
                        //     ?.isChecked
                        // }
                      />
                    </div>
                  ),
              },
              {
                key: `${nType.id}-${nrType.id}-${nChannel.id}-0-placeholders`,
                label: t('Available placeholders'),
                children: [
                  {
                    key: `${nType.id}-${nrType.id}-${nChannel.id}-0-placeholder-list`,
                    label: (
                      <div className="placeholder-list">
                        {defaultNotifications?.[
                          `${nType.id}-${nrType.id}-${nChannel.id}`
                        ]?.placeholders?.map((p, i) => (
                          <Fragment key={i}>
                            {p}
                            <br />
                          </Fragment>
                        ))}
                      </div>
                    ),
                  },
                ],
              },
            ],
          });
        }
      }

      const label = (
        <div className="notification-tree-column-container">
          <div className="notification-tree-column">{nType.title}</div>
          <div className="notification-tree-column notification-info-column">
            <Tooltip text={nType.description}>
              <span className="notification-info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </Tooltip>
          </div>

          {notificationRecipient?.map((nrType) =>
            notificationChannel?.map((nChannel) => (
              <div
                key={`${nType.id}-${nrType.id}-${nChannel.id}`}
                className="notification-tree-column"
              >
                <Field
                  as={Checkbox}
                  disabled={
                    !isNotificationEnabled(
                      nChannel,
                      values,
                      `${nType.id}-${nrType.id}-${nChannel.id}`
                    )
                  }
                  name={`${nType.id}-${nrType.id}-${nChannel.id}.isChecked`}
                  checked={
                    values[`${nType.id}-${nrType.id}-${nChannel.id}`]?.isChecked
                  }
                  className={`notification-${nType.id}-${nrType.id}-${nChannel.id}-checkbox`}
                />
              </div>
            ))
          )}
        </div>
      );

      tree.push({
        key: nType.id,
        label,
        children: rootChildren,
      });
    }

    return tree;
  }, [
    notificationChannel,
    notificationRecipient,
    notificationType,
    values,
    defaultNotifications,
    setFieldValue,
    t,
  ]);

  return (
    <div className="notifications-page notifications-tab">
      <p className="notification-settings-info">
        {t(
          'Each message can contain placeholders, which represent dynamic text.'
        )}
        &nbsp;
        {t('They are placed inside { and }.')}
        <br />
        {t(
          'Available placeholders can be found inside the expandable section in the message you are editing.'
        )}
      </p>

      <Tree value={notificationTree} style={{ width: '100%' }} />
    </div>
  );
}

export default NotificationsCustomersForm;
