import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import usePrevious from '../../../hooks/usePrevious';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { ErrorResponse } from '../../../types/api';
import { ClientCollection } from '../../../types/api/clients';
import { Unpacked } from '../../../types/util';
import * as clientsGuards from '../../../utils/constants/auth/clients';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import {
  AllowedErrors,
  tryApiContextValidation,
} from '../../../utils/helpers/formik';
import { noop } from '../../../utils/helpers/functions';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import { errorToast, successToast } from '../../../utils/helpers/primereact';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  getAdditionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './Clients.functions';
import CreateEditDialog from './Dialogs/CreateEditDialog/CreateEditDialog';
import {
  createDialogApiData,
  editDialogApiData,
} from './Dialogs/CreateEditDialog/CreateEditDialog.functions';
import ForceSetPassword from './Dialogs/ForceSetPassword/ForceSetPassword';
import GroupEditDialog from './Dialogs/GroupEditDialog/GroupEditDialog';
import MergeDialog from './Dialogs/Merge/MergeDialog';
import ViewDialog from './Dialogs/ViewDialog/ViewDialog';
import useTableFilters from './useTableFilters';

function Clients() {
  const { t } = useTranslation();
  usePageTitle(t('Clients'));
  const readGuard = useEndpointGuard(clientsGuards.readClient);
  const createGuard = useEndpointGuard(clientsGuards.createClient);
  const editGuard = useEndpointGuard(clientsGuards.editClient);
  const mergeGuard = useEndpointGuard(clientsGuards.mergeClients);
  const statusChangeGuard = useEndpointGuard(clientsGuards.statusChange);
  const resendEmailGuard = useEndpointGuard(clientsGuards.resendEmail);
  const forgotPasswordGuard = useEndpointGuard(clientsGuards.forgotPassword);
  const { toastRef, bottomRightToastRef } = useContext(ToastContext);
  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const [isAddEditDialogShown, setIsAddEditDialogShown] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [isMergeDialogShown, setIsMergeDialogShown] = useState(false);
  const [isResetPasswordDialogShown, setIsResetPasswordDialogShown] =
    useState(false);
  const [isForceSetPassDialogShown, setIsForceSetPassDialogShown] =
    useState(false);
  const [isGroupEditDialogVisible, setIsGroupEditDialogVisible] =
    useState(false);
  const [mergeClient, setMergeClient] =
    useState<Unpacked<ClientCollection> | undefined>();
  const createEditDialogFormRef = useRef<FormikProps<any>>(null);
  const [action, setAction] = useState<string>('');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    selectionMultiple,
    setSelectionMultiple,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Unpacked<ClientCollection>>(tableStorageKey);

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'clients',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        getAdditionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          mergeGuard,
          statusChangeGuard,
          resendEmailGuard,
          forgotPasswordGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...getAdditionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          mergeGuard,
          statusChangeGuard,
          resendEmailGuard,
          forgotPasswordGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      readGuard,
      editGuard,
      mergeGuard,
      statusChangeGuard,
      resendEmailGuard,
      forgotPasswordGuard,
    ]
  );

  const { data, isLoading, reload, error } = useAxiosHook<ClientCollection>({
    url: '/clients' + queryString(httpQueryObject(httpFiltersObj)),
  });

  const {
    data: headquarterData,
    isLoading: isHeadquarterDataLoading,
    reload: reloadHeadquarterData,
  } = useAxiosHook('', { skipWhen: contextMenuSelection === undefined });

  const {
    reload: formSubmissionRequest,
    isLoading: isFormSubmissionRequestLoading,
  } = useAxios();

  const {
    data: resetPasswordData,
    error: resetPasswordError,
    isLoading: isResetPasswordLoading,
    reload: reloadResetPassword,
  } = useAxiosHook();

  const { reload: updateStatus } = useAxios(
    `/clients/${contextMenuSelection?.id}/status`,
    undefined,
    {
      method: 'PUT',
      skipWhen: true,
      successCallback: () => {
        reload();

        toastRef?.current?.show({
          severity: 'success',
          summary: t('Status change'),
          detail: t("{{name}}'s status successfully updated", {
            name: contextMenuSelection?.ime,
          }),
          life: 5000,
        });
      },
      errorCallback: () => {
        toastRef?.current?.show({
          severity: 'error',
          summary: t('Error'),
          detail: t(
            "An error occured while trying to change {{name}}'s status.",
            { name: contextMenuSelection?.ime }
          ),
          life: 5000,
        });
      },
    }
  );

  const { reload: resendEmailRequest } = useAxios(
    `/clients/${contextMenuSelection?.id}/reinit-activation`,
    undefined,
    {
      method: 'POST',
      skipWhen: true,
      successCallback: () => {
        reload();

        toastRef?.current?.show({
          severity: 'success',
          summary: t('Success'),
          detail: t('Successfully resent an activation email to {{name}}.', {
            name: contextMenuSelection?.ime,
          }),
          life: 5000,
        });
      },
      errorCallback: () => {
        toastRef?.current?.show({
          severity: 'error',
          summary: t('Error'),
          detail: t(
            'An error occured while trying to resend an activation email to {{name}}.',
            { name: contextMenuSelection?.ime }
          ),
          life: 5000,
        });
      },
    }
  );

  const prevResetPasswordData = usePrevious(resetPasswordData);
  const prevResetPasswordError = usePrevious(resetPasswordError);

  useEffect(() => {
    if (!resetPasswordData || resetPasswordData === prevResetPasswordData) {
      return;
    }

    if (toastRef?.current) {
      // Error responses consist of either erroneous HTTP status code (e.g., 400) or [0]
      if (Array.isArray(resetPasswordData) && resetPasswordData[0] === 0) {
        errorToast(
          toastRef,
          t('Error'),
          t(
            'An error occured while trying to send an email reset password to {{name}}.',
            {
              name: contextMenuSelection?.ime,
            }
          )
        );

        return;
      }

      successToast(
        toastRef,
        t('Success'),
        t('An email reset password has been sent to {{name}}.', {
          name: contextMenuSelection?.ime,
        })
      );
    }

    reload();
    // eslint-disable-next-line no-sparse-arrays
  }, [
    contextMenuSelection?.ime,
    prevResetPasswordData,
    reload,
    resetPasswordData,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!resetPasswordError || resetPasswordError === prevResetPasswordError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t(
          'An error occured while trying to send an email reset password to {{name}}.',
          {
            name: contextMenuSelection?.ime,
          }
        )
      );
    }
  }, [
    contextMenuSelection?.ime,
    prevResetPasswordError,
    resetPasswordError,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!readGuard) {
      setIsViewDialogShown(false);
    }
  }, [readGuard]);

  function handleExportExcel() {
    downloadFile(
      `/clients/export/excel` +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Clients'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function handleExportCSV() {
    downloadFile(
      '/clients/export/csv' +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Clients'), undefined, true),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  useEffect(() => {
    if (!createGuard && isAddEditDialogShown && !isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [createGuard, isAddEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!editGuard && isAddEditDialogShown && isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [editGuard, isAddEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!mergeGuard) {
      setIsMergeDialogShown(false);
    }
  }, [mergeGuard]);

  function handleAddClientBtnClick() {
    setContextMenuSelection(undefined);
    setIsEditDialog(false);
    setIsAddEditDialogShown(true);
  }

  function handleMergeClientsBtnClick() {
    setMergeClient(undefined);
    setIsMergeDialogShown(true);
  }

  // Context menu functions
  const handleCMViewClick = useCallback(() => {
    setIsViewDialogShown(true);
  }, []);

  const handleCMEditClick = useCallback(() => {
    setIsEditDialog(true);
    setIsAddEditDialogShown(true);
  }, []);

  const handleStatusChange = useCallback(
    (newStatus: number) => {
      updateStatus({
        payload: { status_id: newStatus },
      });
    },
    [updateStatus]
  );

  const handleCMApproveClick = useCallback(() => {
    handleStatusChange(2);
  }, [handleStatusChange]);

  const handleCMRejectClick = useCallback(() => {
    handleStatusChange(3);
  }, [handleStatusChange]);

  const handleCMActivateClick = useCallback(() => {
    handleStatusChange(4);
  }, [handleStatusChange]);

  const handleCMDeactivateClick = useCallback(() => {
    handleStatusChange(5);
  }, [handleStatusChange]);

  const handleCMResendEmailClick = useCallback(() => {
    resendEmailRequest();
  }, [resendEmailRequest]);

  const handleCMMergeClick = useCallback(() => {
    setMergeClient(contextMenuSelection);
    setIsMergeDialogShown(true);
  }, []);

  const handleCMResetPasswordClick = useCallback(() => {
    setIsResetPasswordDialogShown(true);
  }, []);

  const handleCMForceResetPasswordClick = useCallback(() => {
    setIsForceSetPassDialogShown(true);
  }, []);

  function handleCreateEditDialogHide() {
    setIsAddEditDialogShown(false);
  }

  function handleViewDialogHide() {
    setIsViewDialogShown(false);
  }

  function handleViewDialogEditBtnClick() {
    setIsViewDialogShown(false);
    setIsEditDialog(true);
    setIsAddEditDialogShown(true);
  }

  function handleMergeDialogHide() {
    setIsMergeDialogShown(false);
  }

  function handleForceSetPassDialogHide() {
    setIsForceSetPassDialogShown(false);
  }
  function handleGroupEditDialogHide() {
    setIsGroupEditDialogVisible(false);
  }

  function handleClientAdd(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Client {{name}} has been added successfully', { name: name })
        : t('An error occured while adding {{name}}.', { name: name }),
      life: 5000,
    });
    if (isSuccess) setIsAddEditDialogShown(false);
    reload();
  }

  function handleClientEdit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Client {{name}} has been edited successfully', { name: name })
        : t('An error occured while editing {{name}}.', { name: name }),
      life: 5000,
    });
    if (isSuccess) setIsAddEditDialogShown(false);
    reload();
  }

  function handleCreateEditDialogFormSubmission(values: any) {
    const allowedErrors: AllowedErrors = {
      ime: {
        already_in_use: t('A client with this name already exists'),
      },
      email: {
        already_in_use: t('A client with this email already exists'),
        duplicate: t('A client with this email already exists'),
      },
      mobilen: {
        already_in_use: t('A client with this mobile number already exists'),
      },
      telefon: {
        already_in_use: t('A client with this phone number already exists'),
      },
      maticen_broj: {
        combination_ssn_tax_already_in_use: t(
          'A client with this SSN - Tax No. combination already exists'
        ),
      },
      danocen_broj: {
        combination_ssn_tax_already_in_use: t(
          'A client with this SSN - Tax No. combination already exists'
        ),
      },
    };

    if (isEditDialog) {
      formSubmissionRequest({
        url: `/clients/${contextMenuSelection?.id}`,
        payload: editDialogApiData(
          values,
          headquarterData,
          contextMenuSelection!.id,
          createEditDialogFormRef.current?.initialValues
        ),
        method: 'PUT',
        successCallback: () => {
          handleClientEdit(values.ime, true);
        },
        errorCallback: (err: AxiosError<ErrorResponse>) => {
          const errorDescription = err.response?.data.error_description;

          if (
            errorDescription &&
            tryApiContextValidation(
              errorDescription,
              createEditDialogFormRef,
              allowedErrors
            )
          ) {
            return;
          }

          handleClientEdit(values.ime, false);
        },
      });
    } else {
      formSubmissionRequest({
        url: '/clients',
        payload: createDialogApiData(values, headquarterData),
        method: 'POST',
        successCallback: () => {
          handleClientAdd(values.ime, true);
        },
        errorCallback: (err: AxiosError<ErrorResponse>) => {
          const errorDescription = err.response?.data.error_description;

          if (
            errorDescription &&
            tryApiContextValidation(
              errorDescription,
              createEditDialogFormRef,
              allowedErrors
            )
          ) {
            return;
          }

          handleClientAdd(values.ime, false);
        },
      });
    }
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        handleCMViewClick();
      }
      if (action === 'edit') {
        handleCMEditClick();
      }
      if (action === 'merge') {
        handleCMMergeClick();
      }
      if (action === 'approve') {
        handleCMApproveClick();
      }
      if (action === 'reject') {
        handleCMRejectClick();
      }
      if (action === 'activate') {
        handleCMActivateClick();
      }
      if (action === 'deactivate') {
        handleCMDeactivateClick();
      }
      if (action === 'resend-email') {
        handleCMResendEmailClick();
      }
      if (action === 'reset-password') {
        handleCMResetPasswordClick();
      }
      if (action === 'force-reset-password') {
        handleCMForceResetPasswordClick();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    handleCMViewClick,
    handleCMEditClick,
    handleCMMergeClick,
    handleCMActivateClick,
    handleCMRejectClick,
    handleCMDeactivateClick,
    handleCMApproveClick,
    handleCMResendEmailClick,
    handleCMResetPasswordClick,
    handleCMForceResetPasswordClick,
  ]);

  return (
    <div className="page clients-page">
      <HeaderPages
        title={t('Clients')}
        subtitle={t('View and manage clients')}
        icon={faUsers}
      >
        {createGuard && (
          <Button
            type="button"
            label={t('Add client')}
            icon="fas fa-plus"
            disabled={isLoading}
            onClick={handleAddClientBtnClick}
            className="main-btn"
            data-cy="add-btn"
          />
        )}

        {mergeGuard && (
          <Button
            type="button"
            label={t('Merge clients')}
            icon="fas fa-people-arrows"
            disabled={isLoading}
            onClick={handleMergeClientsBtnClick}
            className={
              createGuard
                ? 'main-btn p-button-secondary p-button-text'
                : 'main-btn'
            }
            data-cy="merge-btn"
          />
        )}
      </HeaderPages>

      <CreateEditDialog
        formRef={createEditDialogFormRef}
        isShown={isAddEditDialogShown}
        isEditDialog={isEditDialog}
        selectedRow={contextMenuSelection}
        headquarterData={headquarterData}
        isHeadquarterDataLoading={isHeadquarterDataLoading}
        reloadHeadquarterData={reloadHeadquarterData}
        onHide={handleCreateEditDialogHide}
        onFormSubmision={handleCreateEditDialogFormSubmission}
        isFormSubmissionRequestLoading={isFormSubmissionRequestLoading}
      />

      <GroupEditDialog
        visible={isGroupEditDialogVisible}
        onHide={handleGroupEditDialogHide}
      />

      <ViewDialog
        isShown={isViewDialogShown}
        selectedRow={contextMenuSelection}
        onHide={handleViewDialogHide}
        onEditBtnClick={handleViewDialogEditBtnClick}
      />

      <MergeDialog
        isShown={isMergeDialogShown}
        onHide={handleMergeDialogHide}
        client={mergeClient}
        successCallback={reload}
      />

      <ConfirmDialog
        visible={isResetPasswordDialogShown}
        onHide={() => setIsResetPasswordDialogShown(false)}
        message={t(
          'Are you sure you want to proceed with sending a password reset email to {{user}}?',
          { user: contextMenuSelection?.ime }
        )}
        header={t('Confirmation')}
        icon="fas fa-exclamation-triangle"
        accept={() => {
          reloadResetPassword({
            url: `/clients/forgotpassword`,
            method: 'POST',
            data: {
              email: contextMenuSelection?.email,
            },
          });
        }}
        reject={() => setIsResetPasswordDialogShown(false)}
        acceptLabel={t('Send email')}
        rejectLabel={t('Cancel')}
        style={{ maxWidth: 480 }}
      />

      <ForceSetPassword
        isShown={isForceSetPassDialogShown}
        onHide={handleForceSetPassDialogHide}
        client={contextMenuSelection}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading || isResetPasswordLoading}
            hasError={!!error}
            reload={reload}
            setPage={setPage}
            headerTitle=""
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            clearSelectionObj={httpFiltersObj}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            selectionPageOnly
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={
              readGuard
                ? (e: DataTableRowClickEventParams) => {
                    setContextMenuSelection(e.data);
                    handleCMViewClick();
                  }
                : noop
            }
            exportToCSVButton
            onExportToCSVButtonClick={handleExportCSV}
            exportToExcelButton
            onExportToExcelButtonClick={handleExportExcel}
            selectionMode="multiple"
            minGroupSelection={2}
            paginatorLeft={t('Selected {{clients}} from {{totalClients}}', {
              clients: selectionMultiple.length,
              totalClients: data?.pagination.total,
            })}
            groupActionsModel={[
              {
                label: t('Group Edit'),
                icon: 'fas fa-edit',
                command: () => setIsGroupEditDialogVisible(true),
              },
            ]}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Clients;
