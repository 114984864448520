import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import api from '../utils/api';

type RequestCallback = (newConfig?: AxiosRequestConfig) => void;

type AdditionalConfig = {
  skipWhen: boolean;
};

export type UseAxiosReturn<D, E = object> = {
  data: D | undefined;
  error: AxiosError<E> | undefined;
  isLoading: boolean;
  reload: RequestCallback;
  response: AxiosResponse<D> | undefined;
};

function useAxiosHook<D = object, E = object>(
  axiosConfig?: AxiosRequestConfig | string,
  additionalConfig?: AdditionalConfig
): UseAxiosReturn<D, E> {
  const [data, setData] = useState<D>();
  const [error, setError] = useState<AxiosError<E>>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();

  const isMountedRef = useRef<boolean>(true);
  const axiosCancelToken = useRef(Axios.CancelToken.source());

  const sendRequest = useCallback<RequestCallback>(
    (newConfig?: AxiosRequestConfig) => {
      // Cancel previous API call
      axiosCancelToken.current.cancel();
      axiosCancelToken.current = Axios.CancelToken.source();

      const requestConfig: AxiosRequestConfig = {
        ...(typeof axiosConfig === 'string'
          ? { url: axiosConfig }
          : axiosConfig),
        ...newConfig,
        cancelToken: axiosCancelToken.current.token, // Add new `CancelToken`
      };

      setLoading(true);

      api(requestConfig)
        .then((response: AxiosResponse) => {
          if (isMountedRef.current) {
            setData(response.data);
          }

          if (isMountedRef.current) {
            setError(undefined);
          }

          if (isMountedRef.current) {
            setResponse(response);
          }
        })
        .catch((error: AxiosError<E>) => {
          if (Axios.isCancel(error)) {
            return;
          }

          if (isMountedRef.current) {
            setData(undefined);
          }

          if (isMountedRef.current) {
            setError(error);
          }

          if (isMountedRef.current) {
            setResponse(undefined);
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setLoading(false);
          }
        });
    },
    [JSON.stringify(axiosConfig)] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (
        (typeof axiosConfig !== 'string' && !axiosConfig?.url) ||
        additionalConfig?.skipWhen
      ) {
        setData(undefined);
        setError(undefined);
        setResponse(undefined);
        return;
      }

      sendRequest();
    },
    [JSON.stringify([additionalConfig, axiosConfig]), sendRequest] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      axiosCancelToken.current.cancel(); // eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []);

  return { data, error, isLoading, reload: sendRequest, response };
}

export default useAxiosHook;
