import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../../types/api/clients';
import { LabelValue } from '../../../../types/options';
import * as customerOrdersGuards from '../../../../utils/constants/auth/customerOrders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { renderIcon } from '../../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import AutoCompleteInput from '../../../Forms/AutoCompleteInput/AutoCompleteInput';
import { ClientFilterOption } from '../../BulkOrders/BulkOrders.types';
import FiltersCounter from '../../Components/Filters/FiltersCounter';

type ClientTypeFilter = 'hqOnly' | 'all';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [clientFilterObj, setClientFilterObj] =
    useState<ClientFilterOption | null>(null);
  const [clientFilter, setClientFilter] = useState('');
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().toDate()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? dayjs().toDate()
  );
  const [clientId, setClientId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'client_id') ?? null
  );
  const [clientType, setClientType] = useState<string>(
    () => getSearchQueryParam(location.search, 'headquarter') ?? ''
  );
  const [limitRange, setLimitRange] = useState<string>(
    () =>
      getSearchQueryParam(location.search, 'limit_range_key') ?? 'vkupno_pratki'
  );
  const [orderClients, setOrderClients] = useState<string>(
    () => getSearchQueryParam(location.search, 'order_key') ?? 'vkupno_pratki'
  );
  const [orderBy, setOrderBy] = useState<string>(
    () => getSearchQueryParam(location.search, 'order_value') ?? 'desc'
  );
  const [limitFrom, setLimitFrom] = useState<number>();
  const [limitTo, setLimitTo] = useState<number>();

  const clientFilterGuard = useEndpointGuard(customerOrdersGuards.clientFilter);
  const debouncedLimitFrom = useDebounce(limitFrom, debounceTimeout);
  const debouncedLimitTo = useDebounce(limitTo, debounceTimeout);
  const debouncedClientFilter = useDebounce(clientFilter, debounceTimeout);

  const { data: clientData } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        ime: debouncedClientFilter,
        page: 1,
        limit: 10,
      }),
    { skipWhen: !clientFilterGuard }
  );
  const clientOptions = useMemo(
    () =>
      clientData?.data.map((client) => {
        return {
          label: client.ime,
          value: client,
        };
      }) ?? [],
    [clientData?.data]
  );
  const clientTypeOptions = useMemo<LabelValue<ClientTypeFilter>[]>(
    () => [
      { label: t('All'), value: 'all' },
      { label: t('Headquarters only'), value: 'hqOnly' },
    ],
    [t]
  );
  const limitRangeOptions = useMemo<LabelValue<string>[]>(
    () => [
      { label: t('Total'), value: 'vkupno_pratki' },
      { label: t('Monthly average'), value: 'mesecen_prosek' },
      { label: t('Weekly average'), value: 'nedelen_prosek' },
      { label: t('Last month'), value: 'posleden_mesec' },
      { label: t('Last week'), value: 'posledna_nedela' },
    ],
    [t]
  );
  const orderOptions = useMemo<LabelValue<string>[]>(
    () => [
      { label: t('Ascending'), value: 'asc' },
      { label: t('Descending'), value: 'desc' },
    ],
    [t]
  );

  useEffect(() => {
    if (!clientFilterGuard) {
      setClientId(null);
    }
  }, [clientFilterGuard]);

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      clientFilterObj,
      clientType,
      orderClients,
      orderBy,
      limitRange,
      limitFrom,
      limitTo,
    ],
    [
      clientType,
      dateFrom,
      dateTo,
      clientFilterObj,
      orderClients,
      orderBy,
      limitRange,
      limitFrom,
      limitTo,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage, dateFrom, dateTo]);

  const httpFiltersObj = useMemo(() => {
    const _dateFrom = dayjs(dateFrom as any);
    const _dateTo = dayjs(dateTo as any);
    return {
      client_id: clientFilterObj?.id,
      date_from: httpDateFormat(_dateFrom),
      date_to: httpDateFormat(_dateTo),
      headquarter: clientType === 'hqOnly' ? '1' : null,
      limit_range_key: limitRange,
      limit_range_from: debouncedLimitFrom,
      limit_range_to: debouncedLimitTo,
      order_key: orderClients,
      order_value: orderBy,
      page: haveFiltersChanged ? 1 : page,
      limit: limit,
    };
  }, [
    clientFilterObj,
    clientType,
    dateFrom,
    dateTo,
    haveFiltersChanged,
    limitRange,
    debouncedLimitFrom,
    debouncedLimitTo,
    orderClients,
    orderBy,
    limit,
    page,
  ]);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryAutoCompleteInputParam<ClientFilterOption, ClientResource>({
    param: 'client_id',
    filterValue: clientFilter,
    setFilterValue: setClientFilter,
    filterValueObj: clientFilterObj,
    setFilterValueObj: setClientFilterObj,
    axiosRequestConfig: (clientId) => `/clients/${clientId}`,
    initialFilterValueDataPath: 'ime',
    filterValueObjPath: 'id',
    initialDataModifier: (data) => ({ id: data.id }),
  });
  useSearchQueryParam('headquarter', clientType);
  useSearchQueryParam(
    'limit_range_from',
    debouncedLimitFrom?.toString() ?? null
  );
  useSearchQueryParam('limit_range_to', debouncedLimitTo?.toString() ?? null);
  useSearchQueryDropdownParam(
    'limit_range_key',
    limitRange,
    setLimitRange,
    limitRangeOptions
  );
  useSearchQueryDropdownParam(
    'order_key',
    orderClients,
    setOrderClients,
    orderOptions
  );
  useSearchQueryDropdownParam('order_value', orderBy, setOrderBy, orderOptions);

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    clientId,
    clientType,
    limitRange,
    orderClients,
    limitFrom,
    limitTo,
    orderBy,
  }).filter(Boolean).length;

  const onChangeClientFilter = useCallback(
    (e) => {
      setClientFilter(e);
    },
    [setClientFilter]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                inputId="filter_date_from"
                value={dateFrom ?? undefined}
                onChange={(e) => setDateFrom(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                inputId="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => setDateTo(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>
          {clientFilterGuard && (
            <div className="filter filters">
              <label htmlFor="filter_client">{t('Client')}</label>
              <AutoCompleteInput
                id="filter_client"
                filterValue={clientFilter}
                value={clientFilterObj}
                options={clientOptions}
                forceSelection={false}
                onFilterChange={onChangeClientFilter}
                onSelectionChange={setClientFilterObj}
                className={classNames({
                  sidebar_filter_active: clientFilterObj,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="filter_client_type">{t('Client type')}</label>
            <Dropdown
              name="filter_client_type"
              id="filter_client_type"
              options={clientTypeOptions}
              value={clientType}
              placeholder={t('All')}
              onChange={(e) => setClientType(e.value)}
              className={classNames({
                sidebar_filter_active: clientType,
              })}
            />
          </div>
          {/* here we have empty div filter because Kire want fancy thing :) */}
          <div className="sidebar_filter"></div>
          <div className="sidebar_filter">
            <label htmlFor="filter_number_orders">
              {t('Number of orders')}
            </label>
            <Dropdown
              name="filter_number_orders"
              id="filter_number_orders"
              options={limitRangeOptions}
              value={limitRange}
              placeholder={t('Total')}
              onChange={(e) => setLimitRange(e.value)}
              className={classNames({
                sidebar_filter_active: limitRange,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_range">{t('Range')}</label>
            <div className="sidebar_filter_row">
              <InputNumber
                id="_from"
                min={0}
                step={1}
                value={limitFrom}
                onChange={(e) => setLimitFrom(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: limitFrom,
                  },
                  'number-group'
                )}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <InputNumber
                id="_to"
                min={limitFrom ?? 0}
                step={1}
                value={limitTo}
                onChange={(e) => setLimitTo(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: limitTo,
                  },
                  'number-group'
                )}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_order_by">{t('Order by')}</label>
            <Dropdown
              name="filter_order_by"
              id="filter_order_by"
              options={limitRangeOptions}
              value={orderBy}
              placeholder={t('Total')}
              onChange={(e) => setOrderBy(e.value)}
              className={classNames({
                sidebar_filter_active: limitRange,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_client_type">{t('Order')}</label>
            <Dropdown
              name="filter_client_type"
              id="filter_client_type"
              options={orderOptions}
              value={orderClients}
              placeholder={t('Descending')}
              onChange={(e) => setOrderClients(e.value)}
              className={classNames({
                sidebar_filter_active: orderClients,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      clientFilterObj,
      clientFilter,
      clientFilterGuard,
      clientOptions,
      clientType,
      clientTypeOptions,
      dateFrom,
      dateTo,
      limitRangeOptions,
      limitRange,
      orderOptions,
      orderClients,
      limitFrom,
      limitTo,
      orderBy,
      basicFiltersActiveFilterCount,
      onChangeClientFilter,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setClientId(null);
    setClientType('');
    setLimitRange('');
    setOrderClients('');
    setOrderBy('');
    setLimitFrom(undefined);
    setLimitTo(undefined);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
