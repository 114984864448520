import './DeliveryAttemptsView.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  DeliveryAttemptsItem,
  setOperation,
} from '../../DeliveryAttempts.functions';

type Props = {
  selectedRow: DeliveryAttemptsItem | undefined;
  isShown: boolean;
  onHide: () => void;
};

export function DeliveryAttemptsView({
  selectedRow,
  isShown,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 600px)');

  const { data, isLoading } = useAxiosHook<DeliveryAttemptsItem>(
    {
      url: `/deliverytrackings/${selectedRow?.id}`,
    },
    { skipWhen: !selectedRow?.id }
  );

  const dialogFooter = (
    <div className="p-pt-4">
      {/* <Button
        label={t('Edit')}
        className="p-button-secondary p-button-text"
        disabled={selectedRow?.id === undefined || Number(data?.id) >= 35}
        onClick={() => history.push(`/orders/${data?.id}/edit`)}
      /> */}
      <Button label={t('Close')} onClick={onHide} />
    </div>
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={isLoading ? t('Loading...') : t('View delivery attempts')}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-delivery-attempts-dialog"
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <>
          <h4>{t('General information')}</h4>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>
                  <b>{t('Date')}</b>
                </td>
                <td>{data?.date ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Operation')}</b>
                </td>
                <td>{setOperation(data?.operation!, t) ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Courier Id')}</b>
                </td>
                <td>{data?.courierId ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Courier Name')}</b>
                </td>
                <td>{data?.courierName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Customer Id')}</b>
                </td>
                <td>{data?.customerId ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Customer Name')}</b>
                </td>
                <td>{data?.customerName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Reason Id')}</b>
                </td>
                <td>{data?.reasonId ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Reason Name')}</b>
                </td>
                <td>{data?.reasonName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Comment')}</b>
                </td>
                <td>{data?.comment ?? '-'}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Dialog>
  );
}
